import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { config } from '@encompaas/common';
import { HomePage, LoginPage } from './pages';
import {
  AuthenticatedChildren,
  UnauthenticatedChildren,
} from '@encompaas/common/auth';
import { BannerNotice, ItemPanelPage } from '@encompaas/common/components';
import { useState, useEffect } from 'react';

function ErrorRedirect() {
  const { error } = useParams();

  return <Navigate to='/' state={{ fromError: true, error }} replace />;
}

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (location.state && location.state.fromError) {
      setError(true);
      setErrorMessage(location.state.error);
    }

    if (location.pathname.includes('/DisposalRequest/')) {
      const requestId = location.pathname.split('/DisposalRequest/');

      if (!requestId.length && !requestId[1]) return;

      window.location.href = `${config.REACT_APP_ENC_DISPOSAL_APPROVAL_APP_URL}/${requestId[1]}`;
    }
  }, [location, navigate]);

  return (
    <div className='App' data-testid={TEST_CONSTANTS.APP_CONTAINER}>
      <AuthenticatedChildren>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='error/:error' element={<ErrorRedirect />} />
          <Route path='items/:id' element={<ItemPanelPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </AuthenticatedChildren>
      <UnauthenticatedChildren>
        <Routes>
          <Route path='error/:error' element={<ErrorRedirect />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
      </UnauthenticatedChildren>
      {error && (
        <BannerNotice
          type='warning'
          text={errorMessage}
          onClose={() => setError(false)}
        />
      )}
    </div>
  );
}

export default App;
