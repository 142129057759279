import { Box } from '@encompaas/common/components';
import { AppBar } from '@encompaas/common/components';
import { Typography } from '@mui/material';

export type PanelProps = {
  children: React.ReactNode;
};

//this should be named something like AppsPanel so that it is clear the it isn't a
//generic component
export const Panel = ({ children }: PanelProps) => {
  return (
    <Box
      className='apps-apps-panel'
      alignItems='start'
      justifyContent='start'
      background='none'
      rounded='all'
      direction='column'
      shadow
    >
      <Box background='none' height={3} className={'apps-apps-appbar'}>
        <AppBar title='Applications' background='none' height={3}></AppBar>
      </Box>
      <Box background='none' className={'apps-apps-content'} width={'calc(100% - 4rem)'}>
        <Box className={'apps-apps-header'} background='none' height={3}>
          <Typography variant='h4'>Apps</Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};
