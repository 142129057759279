import { ApplicationPanel, Box } from '@encompaas/common/components';
import { Panel } from './Panel';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export type ApplicationsProps = {
  applications: {
    name: string;
    Logo: (props: any) => JSX.Element;
    url: string;
  }[];
  isLoading: boolean;
};

export const Applications = ({
  applications,
  isLoading,
}: ApplicationsProps) => {

  const sortedApplications = [...applications].sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );

  return (
    <Panel>
      {isLoading ? (
        <Box
          background={'none'}
          alignItems='center'
          justifyContent={'center'}
          width={'100%'}
          height={'calc(100% - 9.5rem)'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          className={'app-panel'}
          background={'none'}
          direction={'column'}
          gap={'small'}
          padding={'none'}
          width={'100%'}
        >
          <Grid container spacing={2}>
            {sortedApplications.length === 0 ? (
              <Grid item xs={12}>
                <p>
                  You currently don't have access to any EncompaaS apps. Your
                  EncompaaS administrator will be able to grant access to the
                  required app.
                </p>
              </Grid>
            ) : (
              sortedApplications.map((app) => (
                <Grid item xs={3} key={app.name}>
                  <ApplicationPanel {...app} size='medium' />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      )}
    </Panel>
  );
};