import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from './Box.js';
import { useTheme, Typography } from '@mui/material';

const ApplicationPanel = ({ name, Logo, className, url, size = 'medium', }) => {
    const theme = useTheme();
    let _className = `ecs-application-panel` + (size == 'medium' ? '' : '-small');
    //can apply custom className
    _className = _className + (className ?? '');
    const [logoWidth, logoHeight] = size == 'medium' ? [4.375, 5] : [2.5, 2.8125];
    // Handle onClick event
    const handleClick = () => {
        if (url) {
            window.open(url, '_blank'); // Opens the URL in a new window/tab
        }
    };
    return (
    //could use the Button component
    jsxs(Box, { className: _className, padding: 'none', alignItems: 'center', justifyContent: 'start', gap: 1.5, onClick: handleClick, "margin-bottom": '5px', style: { cursor: url ? 'pointer' : 'default' }, children: [jsx(Logo, { className: 'ecs-application-panel-logo', width: `${logoWidth}rem`, height: `${logoHeight}rem` }), jsx(Typography, { align: 'center', variant: 'body1', color: theme.palette.info.dark, className: 'ecs-application-panel-name', children: name })] }));
};

export { ApplicationPanel };
